export enum SITE_ID {
  US_SHOPPING = "us-shopping",
  US_TECH = "us-tech",
  US_LOCAL = "us-local",
}

export enum PAGE_ID {
  AFFILIATE_SHOP = "affiliate-shop",
  HOMEPAGE_TOP_DEALS = "homepage-top-deals",
  TOP_DEALS_BY_CAT = "top-deals-by-cat",
  STORE_PAGES = "store-pages",
}

export enum DEAL_SET {
  COUPON = "coupon",
  SALE = "sale",
}

export enum DEAL_FIELD_SET {
  DEALS = "deals",
  DEALS_WITH_DESC = "dealsWithDesc",
  CATEGORY_INFO = "categoryInfo",
  CATEGORY_INFO_WITH_GROUPS = "categoryInfoWithGroups",
  CATEGORY_PATH = "categoryPath",
  SUB_CATEGORIES = "subCategories",
  RETAILER_GROUPS = "retailerGroups",
  PROMOTION_GROUPS = "promotionGroups",
  BRAND_GROUPS = "brandGroups",
}

export enum PROMOTION_TYPE_V2 {
  PERCENTAGE = "percentage",
  FIXED_AMOUNT = "fixedAmount",
  FIXED_AMOUNT_RANGE = "fixedAmountRange",
  PERCENTAGE_RANGE = "percentageRange",
  MONEY_BACK = "moneyBack",
  EXCLUSIVE = "exclusive",
  FREE_SHIPPING = "freeShipping",
  OTHERS = "others",
}

export enum DEALS_FIELD_SET {
  DEALS = "deals",
  DEALS_WITH_DESC = "dealsWithDesc",
  CATEGORY_INFO = "categoryInfo",
  CATEGORY_INFO_WITH_GROUPS = "categoryInfoWithGroups",
  CATEGORY_PATH = "categoryPath",
  SUB_CATEGORIES = "subCategories",
  RETAILER_GROUPS = "retailerGroups",
  PROMOTION_GROUPS = "promotionGroups",
  BRAND_GROUPS = "brandGroups",
  CATEGORY_ATTR_GROUPS = "categoryAttrGroups",
}

export enum MERCHANT_FIELD_SET {
  PROVIDER_MERCHANTS = "providerMerchants",
  MERCHANT_SOURCE_TYPE_AGGREGATION = "merchantSourceTypeAggregation",
}

export enum RETAILER_FIELD_SET {
  RETAILERS = "retailers",
  CATEGORY_GROUPS = "categoryGroups",
  OFFER_TYPE_AGGREGATION = "offerTypeAggregation",
}

export type SearchDealsRequest = {
  advancedSearch?: number;
  brands?: string[];
  bucketIds?: string[];
  categoryAttributes?: string[];
  categories?: number[];
  contentUuid?: string;
  countryCode: "US" | "GB";
  featureId?: string;
  fieldSets: DEAL_FIELD_SET[];
  filters?: string[];
  ids?: string[];
  imageSize?: string[];
  keyword?: string;
  lang: string;
  limit?: number;
  logoSizes?: string[];
  offset?: number;
  orderBy?: string;
  pageId: PAGE_ID;
  promotionTypes?: PROMOTION_TYPE_V2[];
  providers?: string[];
  requestId?: string;
  retailers?: string[];
  retailerTiers?: number[];
  siteId: SITE_ID;
  sortBy?: "dealRank" | "lastModifiedTime" | "expiration" | "newness";
  sourceTypes: DEAL_SET[];
  spellCheck?: number;
  timeFrom?: string;
  timeTo?: string;
};

type Category = {
  count?: number;
  id: string;
  level: string;
  name: string;
  parentId: string;
  parentName?: string;
};

export type Retailer = {
  id: string;
  name: string;
  brand: string;
  rawUrl: string;
  redirectUrl: string;
  retailerTier: number;
  logoUrlsBySizes: Record<string, string>[];
};

export type Deal = {
  id: string;
  sourceId: string;
  sourceType: string;
  title: string;
  provider: string;
  description?: string;
  shortDescription: string;
  redirectUrl: string;
  imageUrlsBySizes?: Record<string, string>[];
  retailer: Retailer;
  startTime?: string;
  endTime?: string;
  couponCode: string;
  promotions?: PromotionTag[];
};

export type SearchDealsResponse = {
  deals: Deal[];
  totalCount: number;
};

export type MerchantV1 = {
  brandName?: string;
  id: string;
  logoUrls?: string[];
  merchantTier?: MerchantTier;
  merchantUrl?: string;
  name?: string;
  originalMerchantUrl?: string;
  provider: string[];
  resizedLogoUrls?: Record<string, string>[];
  unifiedMerchantId: string;
};

type MerchantV2 = {
  dealCount?: number;
  description?: string;
  id: string;
  itemCount?: number;
  items: Item[];
  logoUrl?: string;
  merchantTier?: number;
  merchantUrl?: string;
  name: string;
  originalMerchantUrl?: string;
  productCount?: number;
  providers?: string[];
  shoppingUrl?: string;
  unifiedMerchantId?: string;
};

type ProcessedKeyword = {
  spellCheckedKeyword: string[];
  finalKeyword: string[];
};

type PromotionTag = {
  description?: string;
  type?: PROMOTION_TYPE_V2;
  value?: number;
};

export type Item = {
  categoryAttributes?: Record<string, any>;
  categoryId?: string;
  couponCode?: string;
  currency: string;
  description?: string;
  endTime?: string;
  filteredVendorCount?: number;
  gtin?: string;
  image: string;
  images?: string[];
  isProductDeal?: boolean;
  itemAttributes?: Record<string, any>;
  itemId: string;
  level1Categories?: string[];
  level1CategoryId?: string;
  level2Categories?: string[];
  level2CategoryId?: string;
  level3Categories?: string[];
  level3CategoryId?: string;
  level4Categories?: string[];
  level4CategoryId?: string;
  level5Categories?: string[];
  level5CategoryId?: string;
  level6Categories?: string[];
  level6CategoryId?: string;
  level7Categories?: string[];
  level7CategoryId?: string;
  link: string;
  merchant?: MerchantV2;
  merchantItems: Item[];
  originImage: string;
  price: number;
  promotionTags?: PromotionTag[];
  provider?: string;
  providerMerchant?: MerchantV2;
  salePrice?: number;
  startTime?: string;
  title: string;
  vendor: string;
  vendorCount?: number;
  vendorId: string;
};

type MerchantTier = 1 | 2 | 3 | 4;
export type SearchMerchantsRequest = {
  advancedSearch?: number;
  categories?: string[];
  countryCode: string;
  fieldSets: MERCHANT_FIELD_SET[];
  filters?: string[];
  keyword?: string;
  limit?: number;
  logoSize?: string[];
  merchants?: string[];
  merchantTiers?: MerchantTier[];
  offset?: number;
  orderBy?: string;
  pageId: PAGE_ID;
  providers?: string[];
  siteId: SITE_ID;
  sortBy?: string;
};

/**
 * Deals Endpoint
 * @see{@link https://docs.google.com/document/d/11zBPK4Mnth-jakl5xY2T1kAQTGsIy1tBPbjZCzt7AxI/edit?tab=t.jvz3j0r2x1jq#bookmark=kix.rhthu9rgbfdl}
 */
export type DealsRequest = {
  advancedSearch?: number;
  brands?: string[];
  bucketIds?: string[];
  categoryAttributes?: string[];
  categories?: number[];
  contentUuid?: string;
  countryCode: "US" | "GB";
  featureId?: string;
  fieldSets: DEALS_FIELD_SET[];
  filters?: "productDealsOnly" | "excludeProductDeals";
  ids?: string[];
  imageSizes?: string[];
  keyword?: string;
  lang: string;
  limit?: number;
  logoSizes?: string[];
  offset?: number;
  orderBy?: "ASC" | "DESC";
  pageId: PAGE_ID;
  promotionTypes?: PROMOTION_TYPE_V2[];
  providers?: string[];
  retailerGroupLimit?: number;
  retailers?: string[];
  retailerTiers?: MerchantTier[];
  spellCheck?: number;
  siteId: SITE_ID;
  sortBy?: "dealRank" | "lastModifiedTime";
  sourceTypes: "coupon" | "sale"[];
  timeFrom?: string;
  timeTo?: string;
  verified?: number[];
};

export type RetailerRequest = {
  bucketIds?: string[];
  categories?: string[];
  countryCode: "US" | "GB";
  fieldSets: RETAILER_FIELD_SET[];
  filters?: string[];
  ids?: string[];
  keyword?: string;
  lang: string;
  limit?: number;
  logoSize?: string[];
  offset?: number;
  orderBy?: "ASC" | "DESC";
  pageId: PAGE_ID;
  providers?: string[];
  retailerTiers?: MerchantTier[];
  siteId: SITE_ID;
  sortBy?: "retailerAlphabet" | "customized";
};

export type ItemCountBySourceType = {
  coupon: number;
  dedupedCoupon: number;
  product: number;
  sale: number;
};

export type SearchMerchantsResponse = {
  merchantSourceTypeAggregation: Record<string, ItemCountBySourceType>;
  providerMerchants: MerchantV1[];
  totalCount: number;
};

export type MerchantStoreResponse = {
  categoryGroups: Category[];
  nextOffset: string;
  offerTypeAggregation: Record<string, ItemCountBySourceType>;
  processedKeywords: ProcessedKeyword;
  retailers: Retailer[];
  totalCount: number;
};

export type TopMerchant = MerchantV1 & {
  couponCount: number;
  dealCount: number;
  saleCount: number;
  productCount: number;
};

// Type built for the Merchant context
export type MerchantContextType = {
  bestDeal?: string; // ID of the best deal
  deals: SearchDealsResponse;
  offerTypeAggregation: ItemCountBySourceType;
  retailer: Retailer;
  bestDiscount?: string;
};
