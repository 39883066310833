"use client";

import classNames from "classnames";
import { type FC } from "react";
import { useIntl } from "react-intl";
import { differenceInDays, displayExpiredDate } from "@/utils/merchant";

interface ExpiredDateProps {
  expiredDate: string | undefined;
  showAllDays?: boolean;
}

export const ExpiredDate: FC<ExpiredDateProps> = ({
  expiredDate,
  showAllDays = false,
}) => {
  const intl = useIntl();
  if (!expiredDate) return null;

  const today = new Date();
  const expiredDateObj = new Date(expiredDate);

  const diffDays = differenceInDays(expiredDateObj, today);
  return (
    <p
      className={classNames(
        "text-[0.8125rem] font-[450] leading-4 tracking-[0.26px] text-battleship",
        {
          "font-semibold tracking-normal [&]:text-[#d30d2e]": diffDays === 1,
        },
      )}
    >
      {displayExpiredDate(intl, diffDays, showAllDays)}
    </p>
  );
};
