import { replaceNonAlphanumericUrlPath } from "@yahoo-commerce/util";
import { type IntlShape } from "react-intl";
import {
  type Deal,
  PROMOTION_TYPE_V2,
  type SearchDealsResponse,
  type TopMerchant,
} from "@/types/Accio";

const MERCHANT_LOGO_SIZE = "170x170";
const EXPIRED_UPPER_DAYS = 5;

const getMerchantUrl = (item: TopMerchant) => {
  if (!item.name) return "";
  const name = replaceNonAlphanumericUrlPath(item.name);
  // TODO: update to use new url when store pages go live
  return `https://shopping.yahoo.com/store/${item.unifiedMerchantId}/${name}/`;
};

/**
 * Function to build the best discount string based on the promotion type. This is specifically used
 * in the DataModule component on the Merchant page. This function combines a few types of promotions together
 * to avoid long strings.
 */
const builtBestDiscountTruncated = (
  intl: IntlShape,
  deals: SearchDealsResponse,
) => {
  const bestDeal = deals?.deals?.find(
    (deal) => deal.promotions?.[0].type !== PROMOTION_TYPE_V2.OTHERS,
  );
  const promotion = bestDeal?.promotions?.[0];
  if (promotion) {
    if (
      promotion.type === PROMOTION_TYPE_V2.PERCENTAGE ||
      promotion.type === PROMOTION_TYPE_V2.PERCENTAGE_RANGE
    ) {
      return intl.formatMessage(
        { id: "stores.PERCENT_OFF" },
        { s1: `${promotion.value}%` },
      );
    } else if (
      promotion.type === PROMOTION_TYPE_V2.FIXED_AMOUNT ||
      promotion.type === PROMOTION_TYPE_V2.FIXED_AMOUNT_RANGE
    ) {
      return intl.formatMessage(
        { id: "stores.FIXED_AMOUNT_OFF" },
        { s1: `$${promotion.value}` },
      );
    } else if (promotion.type === PROMOTION_TYPE_V2.MONEY_BACK) {
      return intl.formatMessage(
        { id: "stores.MONEY_BACK" },
        { s1: `$${promotion.value}` },
      );
    } else if (promotion.type === PROMOTION_TYPE_V2.EXCLUSIVE) {
      return intl.formatMessage({ id: "stores.EXCLUSIVE" });
    } else if (promotion.type === PROMOTION_TYPE_V2.FREE_SHIPPING) {
      return intl.formatMessage({ id: "stores.FREE_SHIPPING" });
    }
  }
  return "";
};

/**
 * Function to build the discount string based on the promotion type. This is specifically used
 * in the MerchantCouponsDealsList component on the Merchant page.
 */
const builtDiscountStr = (intl: IntlShape, deal: Deal) => {
  const promotion = deal?.promotions?.[0];
  if (promotion) {
    if (promotion.type === PROMOTION_TYPE_V2.PERCENTAGE) {
      return intl.formatMessage(
        { id: "stores.PERCENT_OFF" },
        {
          s1: () =>
            `<span class="text-[1.125rem]/[1.375rem] sm:text-xl">${promotion.value}%</span>`,
        },
      );
    } else if (promotion.type === PROMOTION_TYPE_V2.PERCENTAGE_RANGE) {
      return intl.formatMessage(
        { id: "stores.PERCENT_RANGE_OFF" },
        {
          s1: () =>
            `<span class="text-[1.125rem]/[1.375rem] sm:text-xl">${promotion.value}%</span>`,
        },
      );
    } else if (promotion.type === PROMOTION_TYPE_V2.FIXED_AMOUNT) {
      return intl.formatMessage(
        { id: "stores.FIXED_AMOUNT_OFF" },
        {
          s1: () =>
            `<span class="text-[1.125rem]/[1.375rem] sm:text-xl">$${promotion.value}</span>`,
        },
      );
    } else if (promotion.type === PROMOTION_TYPE_V2.FIXED_AMOUNT_RANGE) {
      return intl.formatMessage(
        { id: "stores.FIXED_AMOUNT_OFF_RANGE" },
        {
          s1: () =>
            `<span class="text-[1.125rem]/[1.375rem] sm:text-xl">$${promotion.value}</span>`,
        },
      );
    } else if (promotion.type === PROMOTION_TYPE_V2.MONEY_BACK) {
      return intl.formatMessage(
        { id: "stores.MONEY_BACK" },
        {
          s1: () =>
            `<span class="text-[1.125rem]/[1.375rem] sm:text-xl">$${promotion.value}</span>`,
        },
      );
    } else if (promotion.type === PROMOTION_TYPE_V2.EXCLUSIVE) {
      return intl.formatMessage({ id: "stores.EXCLUSIVE" });
    } else if (promotion.type === PROMOTION_TYPE_V2.FREE_SHIPPING) {
      return intl.formatMessage({ id: "stores.FREE_SHIPPING" });
    }
  }
  return "";
};

const differenceInDays = (date1: Date, date2: Date): number => {
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
  const diffDays = Math.ceil(
    Math.abs((date2.getTime() - date1.getTime()) / oneDay),
  );
  return diffDays;
};

const displayExpiredDate = (
  intl: IntlShape,
  diffDays: number,
  showAllDays: boolean = false,
): string => {
  if (diffDays > EXPIRED_UPPER_DAYS && !showAllDays) return "";
  return diffDays > 1
    ? intl.formatMessage({ id: "stores.deals.DAYS_LEFT" }, { days: diffDays })
    : intl.formatMessage({ id: "stores.deals.EXPIRES" });
};

const shouldShowExpiredDate = (expiredDate?: string) => {
  if (!expiredDate) return false;
  const diffDays = differenceInDays(new Date(expiredDate), new Date());
  return diffDays <= EXPIRED_UPPER_DAYS;
};

export {
  builtDiscountStr,
  builtBestDiscountTruncated,
  differenceInDays,
  displayExpiredDate,
  getMerchantUrl,
  MERCHANT_LOGO_SIZE,
  shouldShowExpiredDate,
};
